<!--
 * @Date: 2021-03-18 09:46:02
 * @LastEditors: frank
 * @LastEditTime: 2021-05-28 13:42:01
 * @FilePath: /shop_frontend/src/views/orderList/detail.vue
 * @Description: Description
-->
<template>
  <section class="page detail">
    <a-row>
      <a-col span="8">订单号：{{ info.number || "" }}</a-col>
      <a-col span="8">订单状态：{{ info.status | statusShow }}</a-col>
    </a-row>

    <div style="padding: 20px 0">
      <a-steps :current="step">
        <a-step title="买家已下单" :description="info.create_time" />
        <a-step title="买家已付款" :description="info.payment_time" />
        <a-step title="卖家已发货" :description="info.delivery_time" />
        <a-step title="买家确认收货" :description="info.receiving_time" />
        <a-step title="订单完成" :description="info.completion_time" />
      </a-steps>
    </div>

    <a-row :gutter="24" type="flex" justify="space-between">
      <a-col :span="12">
        <div style="border: 1px solid #3b91f7; padding: 20px; height: 160px">
          <h3>订单信息</h3>
          <a-row>
            <a-col span="12">订单状态：{{ info.status | statusShow }}</a-col>
            <a-col span="12">订单金额：{{ info.total_amount || "" }}元</a-col>
            <a-col span="12">下单时间：{{ info.create_time || "" }}</a-col>
            <a-col span="12"
              >配送方式：{{
                info.delivery_mode == "0" ? "快递" : "自提"
              }}</a-col
            >
            <a-col span="12">订单号：{{ info.number || "" }}</a-col>
            <a-col span="12"
              >下单人昵称：<span
                style="color: #1890ff; cursor: pointer"
                @click="toMember(info)"
                >{{ info.member_name || "" }}</span
              ></a-col
            >
            <a-col span="12">下单人手机：{{ info.member_phone || "" }}</a-col>
          </a-row>
        </div>
      </a-col>
      <a-col :span="12">
        <div style="border: 1px solid #3b91f7; padding: 20px; height: 160px">
          <h3>用户信息</h3>
          <a-row>
            <a-col span="12">收货客户：{{ info.client_name || "" }}</a-col>
            <a-col span="12">收货人手机号：{{ info.client_phone || "" }}</a-col>
            <a-col span="24"
              >收货地址：{{
                info.client_province +
                  info.client_city +
                  info.client_county +
                  info.client_address || ""
              }}</a-col
            >
            <a-col span="24">买家留言：{{ info.client_message || "" }}</a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>

    <div style="margin: 20px 0">卖家备注：</div>

    <div v-if="logisticsList.length > 0">
      <h3>快递信息</h3>
      <div class="page__table">
        <table class="custom__table" style="margin: 20px 0">
          <colgroup>
            <col width="15%" />
            <col width="10%" />
            <col width="5%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
          </colgroup>
          <tr style="background: #f5f5f5">
            <td>商品名称</td>
            <td>规格</td>
            <td>配送数量</td>
            <td>物流公司</td>
            <td>物流单号</td>
            <td>发货时间</td>
            <td>确认收货时间</td>
          </tr>
          <tr v-for="(el, cIndex) in logisticsList" :key="cIndex">
            <td>
              <div class="flex">
                <img
                  :src="el.spec_picture_url"
                  alt=""
                  style="
                    display: inline-block;
                    width: 80px;
                    height: 80px;
                    margin-right: 10px;
                  "
                />
                <p style="display: inline-block">{{ el.goods_name }}</p>
              </div>
            </td>
            <td>{{ el.spec_name || "" }}</td>
            <td>{{ el.quantity || "" }}</td>

            <td>{{ el.parentInfo.delivery_name || "" }}</td>
            <td :rowspan="el.parentInfo.logistics_goods_items.length" v-if="el.isShow">
              <!-- <div>{{ el.parentInfo.waybill_id || "" }}</div> -->
              <div
                style="
                  display: flex;
                  align-items: center;
                  flex-flow: column nowrap;
                "
              >
                <EditableCell
                  :text="el.parentInfo.waybill_id"
                  :logistics="el.parentInfo.logistics_company"
                  @change="onCellChange($event, el.parentInfo)"
                  style="width: auto"
                ></EditableCell>
                <div>
                  <a-button
                    size="small"
                    type="link"
                    @click="openSearch(el.parentInfo.waybill_id)"
                    >查询</a-button
                  >
                </div>
              </div>
            </td>
            <td>{{ el.parentInfo.create_time || "" }}</td>
            <td>{{ el.parentInfo.receiving_time || "" }}</td>
          </tr>
        </table>
      </div>
    </div>

    <a-divider />

    <div>
      <div class="page__table">
        <table class="custom__table">
          <colgroup>
            <col width="20%" />
            <col width="20%" />
            <col width="5%" />
            <col width="5%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
          </colgroup>
          <tr style="background: #f5f5f5; font-weight: 700;">
            <td>商品</td>
            <td>货号/规格编码</td>
            <td>单价</td>
            <td>数量</td>
            <td>下单人</td>
            <td>收货人</td>
            <td>订单状态</td>
            <td>支付金额</td>
          </tr>
        </table>

        <table class="custom__table" style="margin: 20px 0">
          <colgroup>
            <col width="20%" />
            <col width="20%" />
            <col width="5%" />
            <col width="5%" />
            <col width="10%" />
            <col width="10%" />
            <!-- <col width="10%" /> -->
            <col width="10%" />
            <col width="10%" />
          </colgroup>
          <tr style="background: #f5f5f5">
            <td colspan="9">
              <div class="flex custom__table_order">
                <span>订单号：{{ info.number }}</span>
                 <span>下单时间：{{ info.payment_time }}</span>
                <span
                  >配送方式：{{
                    info.delivery_mode == "0" ? "快递" : "自提"
                  }}</span
                >
                <span class="btns">
                  <span @click="openPay(info)">手动退款</span>
                  <span @click="$router.push({ name: 'goodsEvaluate' })"
                    >查看评论</span
                  >
                </span>
              </div>
            </td>
          </tr>
          <tr v-for="(el, cIndex) in info.sales_goods_items" :key="el.id">
            <td>
              <div class="flex">
                <img
                  :src="el.spec_picture_url"
                  alt=""
                  style="
                    display: inline-block;
                    width: 80px;
                    height: 80px;
                    margin-right: 10px;
                  "
                />
                <p style="display: inline-block">{{ el.goods_name }}</p>
              </div>
            </td>
            <td>{{ el.spec_name }}</td>
            <td>{{ el.member_price }}</td>
            <td>{{ el.quantity }}</td>
            <td :rowspan="info.sales_goods_items.length" v-if="cIndex === 0">
              {{ info.member_name }}
            </td>
            <td :rowspan="info.sales_goods_items.length" v-if="cIndex === 0">
               {{ info.client_name }}
            </td>
            <!-- <td :rowspan="info.sales_goods_items.length" v-if="cIndex === 0">
              {{ info.payment_time }}
            </td> -->
            <td :rowspan="info.sales_goods_items.length" v-if="cIndex === 0">
              <span>{{ info.status | statusShow }}</span>
              <div>
                <a-button
                  type="primary"
                  size="small"
                  @click="sendGoods(info)"
                  v-if="info.status == 1"
                  >发货</a-button
                >
              </div>
            </td>
            <td :rowspan="info.sales_goods_items.length" v-if="cIndex === 0">
              {{ info.payment_amount }}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <a-modal
      title="填入快递信息发货"
      :visible="infoVisible"
      @ok="handleOkInfo"
      @cancel="infoVisible = false"
      width="70%"
    >
      <div>
        <div>
          <div style="margin-bottom: 20px">
            <span>物流编号：</span>
            <span>
              <a-input v-model="sendNumber" class="w220"></a-input>
            </span>
          </div>
        </div>
        <a-table
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
          :columns="columns"
          :data-source="sendData"
          :pagination="false"
        >
          <div slot="sendQuantity" slot-scope="text, record">
            <a-input
              v-model="record.sendQuantity"
              class="w220"
              type="number"
            ></a-input>
          </div>
        </a-table>
      </div>
    </a-modal>
    <a-modal
      title="手动退款"
      :visible="visible"
      @ok="handleOk"
      @cancel="visible = false"
    >
      <div>
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-form-model-item label="退款说明" prop="description">
            <a-input v-model="form.description" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </section>
</template>

<script>
const columns = [
  {
    title: "商品名称",
    dataIndex: "goods_name",
    align: 'center'
  },
  {
    title: "规格",
    dataIndex: "spec_name",
    align: 'center'
  },
  {
    title: "单价",
    dataIndex: "price",align: 'center'
  },
  {
    title: "数量",
    dataIndex: "quantity",
    align: 'center'
  },
  {
    title: "发货数量",
    dataIndex: "sendQuantity",
    scopedSlots: { customRender: "sendQuantity" },
    align: 'center'
  },
];
export default {
  data() {
    return {
      columns,
      info: {},
      visible: false,
      infoVisible: false,
      currentSendGoods: {},
      sendNumber: "",
      sendData: [],
      selectedRowKeys: [],
      logisticsList: [],
      currentRefund: {},
      form: { description: "" },
      rules: {
        description: [
          {
            required: true,
            message: "请输入退款说明",
            trigger: "blur",
          },
        ],
      },
    };
  },
  components: {
    EditableCell: () => import("./EditableCell"),
  },
  computed: {
    step() {
      return {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        7: 4,
      }[`${this.info.status}`];
    },
  },
  watch: {},
  created() {
    this.getDetail();
  },
  mounted() {},
  methods: {
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$axios
            .post(`/sales_orders/${this.currentRefund.id}/refund/`, this.form)
            .then((res) => {
              this.getDetail();
              this.visible = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    toMember(obj) {
      console.log(obj, "----toMember");
      if (!obj.member) {
        return
      }
      this.$axios.get(`/members/${obj.member}/`).then((res) => {
        this.$router.push({
          name: "memberDetail",
          query: {
            info: JSON.stringify(res),
          },
        });
      });
    },
    onCellChange(e, info) {
      console.log(e, info);
      this.$axios
        .post(`/sales_logistics/${info.id}/set_waybill_id/`, { waybill_id: e.text, logistics_company: e.logistics })
        .then((res) => {
          this.getDetail();
        });
    },
    openSearch(id) {
      window.open(
        `https://www.kuaidi100.com/all/st.shtml?mscomnu=${id}`,
        "_blank"
      );
    },
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    sendGoods(obj) {
      this.infoVisible = true;
      this.currentSendGoods = obj;
      this.sendNumber = "";
      this.sendData = JSON.parse(JSON.stringify(obj.sales_goods_items));
      this.sendData.forEach((el) => {
        this.$set(el, "sendQuantity", el.quantity);
      });
      console.log(obj, "obj");
    },
    handleOkInfo() {
      console.log(this.sendData, "send");

      if (!this.sendNumber) {
        this.$message.warn("请输入物流编号");
        return;
      }

      if (this.selectedRowKeys.length === 0) {
        this.$message.warn("请勾选发货数据");
        return;
      }

      this.$axios
        .post("/sales_logistics/", {
          sales_order: this.currentSendGoods.id,
          waybill_id: this.sendNumber,
          logistics_goods_items: this.sendData.map((el) => {
            return {
              sales_goods: el.id,
              quantity: el.sendQuantity,
            };
          }),
        })
        .then(() => {
          this.$message.success("操作成功");
          this.infoVisible = false;
        });
    },
    openPay(info) {
      console.log(info);
      this.currentRefund = info;
      this.visible = true;
      this.form = {
        description: "",
      };
    },
    getDetail() {
      this.$axios.get(`/sales_orders/${this.$route.query.id}/`).then((res) => {
        this.info = res;
        let list = [];
        this.info.sales_logistics_items.forEach((el) => {
          el.logistics_goods_items &&
            el.logistics_goods_items.forEach((a, i) => {
              list.push({
                parentInfo: el,
                ...a,
                isShow: i === 0 ? true : false
              });
            });
        });

        this.logisticsList = list;

        console.log(this.logisticsList, "logisticsList");
      });
    },
  },
};
</script>

<style scoped lang="less">
.detail {
  padding: 20px;
}
.btns {
  display: flex;
    display: flex;
  flex-flow: row nowrap;
  white-space: nowrap;
  > span {
    margin-left: 20px;
    color: #549ff8;
    cursor: pointer;
  }
}
</style>
